<template>
  <div class="container">
    <div class="mt-5">
      <h3 class="mb-3 text-center">GX娛樂 後台管理系統</h3>
      <form action="" class="form-signin" @submit.prevent="login()">
        <div class="mb-3">
          <label class="form-label">請輸入信箱</label>
          <input
            type="email"
            class="form-control"
            placeholder="name@example.com"
            v-model="email"
          />
        </div>
        <div class="mb-3">
          <label class="form-label">請輸入密碼</label>
          <input type="password" class="form-control" v-model="password" />
        </div>
        <div class="mb-3 text-center">
          <button type="submit" class="btn btn-primary">登入</button>
        </div>
      </form>
    </div>
    <div class="text-center">version 0.0.7</div>
  </div>
</template>

<style scrope>
body {
  background-color: #f5f5f5;
}

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: 0 auto;
}
</style>

<script>
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

export default {
  data() {
    return {
      email: "",
      password: "",
    };
  },
  methods: {
    login() {
      const auth = getAuth();
      signInWithEmailAndPassword(auth, this.email, this.password)
        .then(() => {
          // const user = userCredential.user;
          this.$router.push({ name: "Admin" });
          // console.log(user);
        })
        .catch(() => {
          alert("登入失敗");
        });
    },
  },
};
</script>
